import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Science Communication - Kimberly Plomp, PhD</title>
            <meta name="description" content="List of Communication and Media" />
            <meta name="keywords" content="media,communication,news,articles,print,television,radio,interviews" />
        </Helmet>

        <BannerLanding title="Science Communication" subtitle="Media coverage and interviews" />

        <div id="main">
            <section id="one" className="spotlights">
                <section>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Television</h3>
                            </header>
                            <div className="table-wrapper">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>April 27, 2015</td>
                                            <td>Global BC News Hour</td>
                                            <td><a href="http://globalnews.ca/bc/program/news-hour-bc/" target="_blank" rel="noreferrer">http://globalnews.ca/bc/program/news-hour-bc/</a></td>
                                        </tr>
                                        <tr>
                                            <td>April 27, 2015</td>
                                            <td>Prime with Aaron McArthur</td>
                                            <td><a href="http://globalnews.ca/video/1966926/sfu-researcher-explains-back-story-of-humans-walking-upright" target="_blank" rel="noreferrer">http://globalnews.ca/video/1966926/sfu-researcher-explains-back-story-of-humans-walking-upright</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Selected News Articles</h3>
                            </header>
                            <div className="table-wrapper">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>May 7, 2015</td>
                                            <td>The Naked Scientist</td>
                                            <td><a href="http://www.thenakedscientists.com/HTML/science-news/news/1000680/" target="_blank" rel="noreferrer">http://www.thenakedscientists.com/HTML/science-news/news/1000680/</a></td>
                                        </tr>
                                        <tr>
                                            <td>May 5, 2015</td>
                                            <td>Yahoo Health</td>
                                            <td><a href="https://www.yahoo.com/health/got-back-pain-maybe-you-can-blame-your-ancestral-118214061652.html" target="_blank" rel="noreferrer">https://www.yahoo.com/health/got-back-pain-maybe-you-can-blame-your-ancestral-118214061652.html</a></td>
                                        </tr>
                                        <tr>
                                            <td>April 27, 2015</td>
                                            <td>Daily Mail</td>
                                            <td><a href="http://www.dailymail.co.uk/news/article-3056809/Bad-Blame-chimps.html" target="_blank" rel="noreferrer">http://www.dailymail.co.uk/news/article-3056809/Bad-Blame-chimps.html</a></td>
                                        </tr>
                                        <tr>
                                            <td>April 27, 2015</td>
                                            <td>Vancouver Sun</td>
                                            <td><a href="http://www.vancouversun.com/touch/story.html?id=11008271" target="_blank" rel="noreferrer">http://www.vancouversun.com/touch/story.html?id=11008271</a></td>
                                        </tr>
                                        <tr>
                                            <td>April 27, 2015</td>
                                            <td>Metro News</td>
                                            <td><a href="http://metro.co.uk/2015/04/27/bad-back-sufferers-have-chimpanzee-vertebrae-study-finds-5169269/" target="_blank" rel="noreferrer">http://metro.co.uk/2015/04/27/bad-back-sufferers-have-chimpanzee-vertebrae-study-finds-5169269/</a></td>
                                        </tr>
                                        <tr>
                                            <td>April 27, 2015</td>
                                            <td>Archaeology Magazine</td>
                                            <td><a href="http://www.archaeology.org/news/3241-150427-vertebrae-shape-bipedalism" target="_blank" rel="noreferrer">http://www.archaeology.org/news/3241-150427-vertebrae-shape-bipedalism</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Radio</h3>
                            </header>
                            <div className="table-wrapper">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>May 7, 2015</td>
                                            <td>Radio Cardiff with Rhys Jones</td>
                                            <td>Wales, UK</td>
                                        </tr>
                                        <tr>
                                            <td>May 7, 2015</td>
                                            <td>Corus Radio with David Taylor</td>
                                            <td>Calgary, AB, Canada</td>
                                        </tr>
                                        <tr>
                                            <td>May 6, 2015</td>
                                            <td>Radio Canada</td>
                                            <td>Montreal, QC, Canada</td>
                                        </tr>
                                        <tr>
                                            <td>April 28, 2015</td>
                                            <td>610 CKTB (Talk Radio)</td>
                                            <td>St. Catharines, ON, Canada</td>
                                        </tr>
                                        <tr>
                                            <td>April 27, 2015</td>
                                            <td>ABC Radio</td>
                                            <td>Melbourne, Australia</td>
                                        </tr>
                                        <tr>
                                            <td>April 27, 2015</td>
                                            <td>CKWX News at 1130</td>
                                            <td>Vancouver, BC, Canada</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Landing
